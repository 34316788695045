import imgLoadingSrc from "../../assets/images/icons/loading.svg";
import { useEffect, useState } from "react";
import {
  FaceLandmarker,
  FaceLandmarkerOptions,
  FaceLandmarkerResult,
  FilesetResolver,
} from "@mediapipe/tasks-vision";
import GlassesCanvas from "./GlassesCanvas";

//@ts-ignore
export default function Camera({ glassez }) {
  // let renderCamera = false;
  // let lastImageUrl = "";
  const [faceLandmarkerResult, setFaceLandmarkerResult] =
    useState<FaceLandmarkerResult>();

  const [video, setVideo] = useState<HTMLVideoElement>();
  const [loading, setLoading] = useState<boolean>(true);
  const [glassImage, setGlassImage] = useState<HTMLImageElement>();

  let faceLandmarker: FaceLandmarker;
  let lastVideoTime = -1;

  let drawWrapElement: HTMLDivElement;

  const hostUrl = process.env.REACT_APP_STATIC_URL;
  const options: FaceLandmarkerOptions = {
    baseOptions: {
      modelAssetPath: hostUrl + `/face_landmarker.task`,
      delegate: "GPU",
    },
    numFaces: 1,
    runningMode: "VIDEO",
    outputFaceBlendshapes: false,
    // outputFaceBlendshapes: true,
    // outputFacialTransformationMatrixes: true,
  };
  const [videoWidth, setVideoWidth] = useState(600);
  const setup = async () => {
    if (!hasGetUserMedia()) {
      alert(
        "Trình duyệt chưa hỗ trợ camera. Vui lòng kiểm tra lại trình duyệt hoặc domain phải có https"
      );
      return;
    }

    const filesetResolver = await FilesetResolver.forVisionTasks(
      // hostUrl + "/vision_wasm_internal.wasm"

      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
    );
    faceLandmarker = await FaceLandmarker.createFromOptions(
      filesetResolver,
      options
    );
    drawWrapElement = document.getElementById("draw-wrap") as HTMLDivElement;
    setVideo(document.getElementById("video") as HTMLVideoElement);

    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then(function (stream) {
        const vid = document.getElementById("video") as HTMLVideoElement;
        vid.srcObject = stream;
        vid.addEventListener("loadeddata", () => {
          const ratio = vid.videoHeight / vid.videoWidth;
          setVideoWidth(vid.videoWidth);
          // const vwidth = vid.videoWidth;
          // vid.style.width = videoWidth + "px";
          // vid.style.height = videoWidth * ratio + "px";
          // drawWrapElement.style.width = vwidth + "px";
          // drawWrapElement.style.height = vwidth * ratio + 50 + "px";
          predictWebcam();
        });
      });
  };

  const predictWebcam = async () => {
    // alert(4);
    const vid = document.getElementById("video") as HTMLVideoElement;
    if (vid) {
      // alert(5);
      let nowInMs = Date.now();
      if (lastVideoTime !== vid.currentTime) {
        lastVideoTime = vid.currentTime;
        let results: FaceLandmarkerResult = faceLandmarker.detectForVideo(
          vid,
          nowInMs
        );
        setFaceLandmarkerResult(results);
        //   drawResult(faceLandmarkerResult);
      }
    }
    window.requestAnimationFrame(predictWebcam);
  };

  function hasGetUserMedia() {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }

  const loadImage = () => {
    // if (lastImageUrl != glassez.src) {
    //   lastImageUrl = glassez.src;
    const imgDom = document.getElementById("glass-image");
    if (imgDom) imgDom.remove();
    const gi = document.createElement("img");
    gi.id = "glass-image";
    gi.src = glassez.imgs[0];
    document.body.appendChild(gi);
    gi.onload = function () {
      setGlassImage(gi);
      // setLoading(false);
    };
    // }
  };

  useEffect(() => {
    loadImage();
    // if (!renderCamera) {
    //   renderCamera = true;
    setup();
    // }
  }, []);

  return (
    <div id="draw-wrap">
      <video
        className="camera-feed"
        id="video"
        autoPlay
        playsInline={true}
        style={{ width: "100%", opacity: loading ? 0 : 1 }}
      ></video>
      {faceLandmarkerResult ? (
        <GlassesCanvas
          videoWidth={videoWidth}
          video={video}
          results={faceLandmarkerResult}
          glassImage={glassImage}
          setLoading={setLoading}
        ></GlassesCanvas>
      ) : (
        ""
      )}
      {loading ? <img src={imgLoadingSrc} className="loading" /> : ""}
    </div>
  );
}
