import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/App.scss";

import Layout from "./layout/Layout";
import Home from "./pages/home/Home";
import { ApiDataProvider } from "./store/ApiDataProvider";
import { GlassezProvider } from "./store/GlassezProvider";
import GlassezDetail from "./pages/detail/GlassezDetail";
import { useLocation } from "react-router-dom";
import { ProductProvider } from "./store/ProductContextProvider";
import Category from "./pages/category/Category";

export default function App() {
  return (
    <ApiDataProvider>
      <ProductProvider>
        <div className="App">
          <GlassezProvider>
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />

                  <Route
                    path="glasses/:title/:id"
                    element={<GlassezDetail />}
                  />
                  <Route path="category/:title/:id" element={<Category />} />
                  {/* <Route path="column" element={<ColumnPage />} /> */}
                  {/* <Route path="*" element={<Home data={data} />} /> */}
                </Route>
              </Routes>
            </BrowserRouter>
          </GlassezProvider>
        </div>
      </ProductProvider>
    </ApiDataProvider>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
