import { useState, Fragment } from "react";
import { Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Menu } from "./_Menu";
import "./_HeaderMenu.scss";

export default function HeaderMenu() {
  const [anchor, setAnchor] = useState(false);
  return (
    <div id="header" className="nh-row">
      <div id="header-row-5" className="nh-row header-top-desktop hidden-xs">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-xs-3"></div>
            <div className="col-sm-9 col-xs-9">
              <ul className="sup-h">
                <li>
                  <span>
                    <i className="fa fa-envelope" aria-hidden="true"></i>Email:
                  </span>
                  info@tueanh.vn
                </li>
                <li>
                  <span>
                    <i className="fa fa-mobile" aria-hidden="true"></i>SĐT Đặt
                    lịch:
                  </span>
                  093.110.8663 - 024.7776.6656
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="header-row-41" className="nh-row">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-xs-3">
              <div className="navbar-header">
                <a className="logo" href="https://tueanh.vn/">
                  <img
                    src="https://tueanh.vn/uploads/plugin/setting/3/1598518080-1975457245-phong-kham-m-t-tu-anh.png"
                    alt="Logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-9 col-xs-9 hidden-xs">
              <div className="slogan-t">
                <p>TUỆ ANH EYECARE - MẮT SÁNG, TÂM AN</p>
              </div>
            </div>
            <div className="col-xs-9 hidden visible-xs-block">
              <Fragment>
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ ml: 2 }}
                  style={{ float: "right", color: "#106d5d" }}
                  onClick={() => setAnchor(true)}
                >
                  <MenuIcon style={{ fontSize: 28 }} />
                </IconButton>
                <Drawer
                  anchor="left"
                  open={anchor}
                  onClose={() => setAnchor(false)}
                  style={{ width: 300 }}
                >
                  <Menu onClose={() => setAnchor(false)} />
                </Drawer>
              </Fragment>
            </div>
          </div>
        </div>
      </div>
      <div id="header-row-68" className="nh-row bg-main top-menu hidden-xs">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-xs-12">
              <div className=" nav_desktop max-992px-hidden   ">
                <Menu />
              </div>
            </div>
            <div className="navbar-right">
              <ul className="donv-login">
                <li className="dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="icon-flag icon-flag-vi hide">&nbsp;</span>
                    <span className="icon_img">
                      <img src="https://tueanh.vn/uploads/plugin/language/4/1613719444-318043814-vi-t-nam.png" />
                    </span>
                  </a>
                  <ul className="dropdown-menu dkm-drop-top" role="menu">
                    <li>
                      <a className="click-language" data-lang="eng" href="#">
                        <span className="icon-flag icon-flag-en hide">
                          &nbsp;
                        </span>
                        <span className="icon_img">
                          <img src="https://tueanh.vn/uploads/plugin/language/3/1613719465-859255780-english.jpg" />
                        </span>
                        English
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <form
                id="form-suggestion"
                data-type="products"
                action="https://tueanh.vn/search/news/basic-search-new"
                method="get"
              >
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control search-suggestion"
                      name="keyword"
                      placeholder="Từ khóa tìm kiếm"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-search btn-main btn-submit"
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
