import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlassez } from "../store/GlassezProvider";
import { useApiData } from "../store/ApiDataProvider";
import { slugify } from "../lib/StrUtils";
import { formatNumber } from "../lib/NumberUtils";
import "./_Gallery.scss";
export default function Gallery() {
  const navigate = useNavigate();
  const { apiData } = useApiData();
  const [categorys, setCategorys] = useState([]);
  useEffect(() => {
    setCategorys(apiData.categorys as []);
  }, [apiData]);

  return (
    <>
      <div className="gallery">
        <div className="title">THỬ KÍNH ONLINE</div>
        <div className="breadcrump">Home &gt; Kính gọng</div>
        <div className="gallery-category">
          {(categorys as []).map((item: any, index) => {
            return (
              <div
                key={"gallery-category" + index}
                className="category"
                onClick={() => {
                  navigate("/category/" + slugify(item.name) + "/" + item.id);
                }}
              >
                <div className="img">
                  <img src={item.thumb} />
                </div>
                <div className="title">{item.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
