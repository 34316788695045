import React, { createContext, useContext, useEffect, useState } from "react";

export interface Product {
  id: string;
  quantity: number;
}

interface ProductContextType {
  products: Product[];
  addProduct: (productId: string, quantity: number) => void;
  removeProduct: (productId: string) => void;
  updateQuantity: (productId: string, quantity: number) => void;
  clearProducts: () => void;
  quantity: number;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

export const useProductContext = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProductContext must be used within a ProductProvider");
  }
  return context;
};

interface ProductProviderProps {
  children: React.ReactNode;
}

export const ProductProvider: React.FC<ProductProviderProps> = ({
  children,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    const storedProducts = localStorage.getItem("products");
    if (storedProducts) {
      setProducts(JSON.parse(storedProducts));
    }
  }, []);

  useEffect(() => {
    const qty = products.reduce(
      (total, product) => total + product.quantity,
      0
    );
    setQuantity(qty);
  }, [products]);

  const addProduct = (productId: string, quantity: number) => {
    const existingProduct = products.find(
      (product) => product.id === productId
    );

    if (existingProduct) {
      updateQuantity(productId, existingProduct.quantity + quantity);
    } else {
      setProducts((prevProducts) => [
        ...prevProducts,
        { id: productId, quantity },
      ]);
      localStorage.setItem(
        "products",
        JSON.stringify([...products, { id: productId, quantity }])
      );
    }
  };

  const updateQuantity = (productId: string, quantity: number) => {
    const updateProduct = products.map((product) =>
      product.id === productId ? { ...product, quantity } : product
    );
    setProducts(updateProduct);

    localStorage.setItem("products", JSON.stringify(updateProduct));
  };

  const clearProducts = () => {
    setProducts([]);
    localStorage.removeItem("products");
  };

  const removeProduct = (productId: string) => {
    const updatedProducts = products.filter(
      (product) => product.id !== productId
    );
    localStorage.setItem("products", JSON.stringify(updatedProducts));
    setProducts(updatedProducts);
  };

  const contextValue: ProductContextType = {
    products,
    addProduct,
    updateQuantity,
    removeProduct,
    clearProducts,
    quantity,
  };

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  );
};
