import React, { createContext, useContext, useState } from "react";

const GlassezContext = createContext<any>(null);

export const GlassezProvider: React.FC<GlassezProviderProps> = ({
  children,
}) => {
  const [glassez, setGlassez] = useState<GlassezState | null>(null);

  const setGlassezData = (
    id: number,
    title: string,
    description: string,
    url: string
  ) => {
    setGlassez({ id, title, description, url });
  };

  return (
    <GlassezContext.Provider value={{ glassez, setGlassezData }}>
      {children}
    </GlassezContext.Provider>
  );
};

export const useGlassez = () => {
  const context = useContext(GlassezContext);
  if (!context) {
    throw new Error("useGlassez must be used within an GlassesProvider");
  }
  return context;
};

interface GlassezProviderProps {
  children: React.ReactNode;
}

interface GlassezState {
  id: number;
  title: string;
  description: string;
  url: string;
}
