import React from "react";
import { Outlet } from "react-router-dom";
import HeaderMenu from "./_HeaderMenu";
import Footer from "./_Footer";
export default function Layout({}) {
  return (
    <>
      <HeaderMenu />
      <Outlet />
      <Footer />
    </>
  );
}
