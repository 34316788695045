import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./_Menu.scss";

type MenuProps = {
  onClose?: () => void;
};

export const Menu = (props: MenuProps) => {
  return (
    <>
      <div className="nav-menu-header hidden">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>Menu chính</span>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={props?.onClose}
          >
            <CloseIcon style={{ fontSize: 28 }} />
          </IconButton>
        </Box>
      </div>
      <ul className="nav-menu clearfix">
        <li className="sub-menu-style1 sub-menu">
          <a href="https://tueanh.vn/"> Trang chủ </a>
        </li>
        <li className="sub-menu-style1 sub-menu">
          <a href="https://tueanh.vn/phong-kham-mat-tue-anh.html">Giới thiệu</a>
        </li>
        <li className="sub-menu-style1 sub-menu">
          <a href="https://tueanh.vn/kinh-mat-hang-hieu">Kính mắt</a>
        </li>
        <li className="sub-menu-style2 sub-menu">
          <a href="https://tueanh.vn/dich-vu"> Dịch vụ </a>
          <ul className="clearfix main_menu_mega">
            <li className="col-sm-4">
              <a href="https://tueanh.vn/dich-vu/tat-khuc-xa-dieu-tri-tat-khuc-xa-bang-cong-nghe-cao">
                TẬT KHÚC XẠ - ĐIỀU TRỊ TẬT KHÚC XẠ BẰNG CÔNG NGHỆ CAO
              </a>
              <ul className="clearfix">
                <li>
                  <a href="https://tueanh.vn/tat-khuc-xa-dieu-tri-tat-khuc-xa-bang-cong-nghe-cao/cac-loai-tat-khuc-xa">
                    Các loại tật khúc xạ
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/tat-khuc-xa-dieu-tri-tat-khuc-xa-bang-cong-nghe-cao/dieu-tri-tat-khuc-xa-khong-can-phau-thuat-phuong-phap-ortho-k">
                    Điều trị tật khúc xạ không cần phẫu thuật - Phương pháp
                    Ortho-K
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/tat-khuc-xa-dieu-tri-tat-khuc-xa-bang-cong-nghe-cao/tinh-an-toan-cua-phuong-phap-orthok">
                    Tính an toàn của phương pháp ORTHO-K
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/tat-khuc-xa-dieu-tri-tat-khuc-xa-bang-cong-nghe-cao/co-che-tac-dong-va-loi-ich-cua-phuong-phap-ortho-k">
                    Cơ chế tác động và lợi ích của phương pháp ORTHO-K
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/tat-khuc-xa-dieu-tri-tat-khuc-xa-bang-cong-nghe-cao/nhuoc-thi">
                    Nhược Thị
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/tat-khuc-xa-dieu-tri-tat-khuc-xa-bang-cong-nghe-cao/kiem-soat-can-thi">
                    Kiểm soát cận thị
                  </a>
                </li>
              </ul>
            </li>
            <li className="col-sm-4">
              <a href="https://tueanh.vn/dich-vu/benh-ly-dich-kinh-vong-mac">
                BỆNH LÝ DỊCH KÍNH VÕNG MẠC
              </a>
              <ul className="clearfix">
                <li>
                  <a href="https://tueanh.vn/benh-ly-dich-kinh-vong-mac/bong-vong-mac">
                    Bong võng mạc
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-dich-kinh-vong-mac/xuat-huyet-dich-kinh-vong-mac">
                    Xuất huyết dịch kính - võng mạc
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-dich-kinh-vong-mac/benh-vong-mac-tieu-duong">
                    Bệnh võng mạc đái tháo đường
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-dich-kinh-vong-mac/thoai-hoa-hoang-diem-tuoi-gia">
                    Thoái hoá hoàng điểm tuổi già
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-dich-kinh-vong-mac/tang-sinh-mang-truoc-vong-mac">
                    Tăng sinh màng trước võng mạc
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-dich-kinh-vong-mac/lo-hoang-diem">
                    Lỗ hoàng điểm
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-dich-kinh-vong-mac/tac-tinh-mach-vong-mac">
                    Tắc tĩnh mạch võng mạc
                  </a>
                </li>
              </ul>
            </li>
            <li className="col-sm-4">
              <a href="https://tueanh.vn/dich-vu/viem-mang-bo-dao">
                VIÊM MÀNG BỒ ĐÀO
              </a>
              <ul className="clearfix">
                <li>
                  <a href="https://tueanh.vn/viem-mang-bo-dao/viem-mang-bo-dao">
                    Viêm màng bồ đào
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/viem-mang-bo-dao/trieu-chung-vmbd">
                    Triệu chứng VMBĐ
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/viem-mang-bo-dao/chuan-doan-va-dieu-tri-vmbd">
                    Chẩn đoán và điều trị VMBĐ
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/viem-mang-bo-dao/phan-loai-va-nguyen-nhan-vmbd">
                    Phân loại và nguyên nhân VMBĐ
                  </a>
                </li>
              </ul>
            </li>
            <li className="col-sm-4">
              <a href="https://tueanh.vn/dich-vu/benh-ly-thuy-tinh-the-va-glaucoma">
                BỆNH LÝ THUỶ TINH THỂ VÀ GLAUCOMA
              </a>
              <ul className="clearfix">
                <li>
                  <a href="https://tueanh.vn/benh-ly-thuy-tinh-the-va-glaucoma/duc-the-thuy-tinh">
                    Đục thể thuỷ tinh
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-thuy-tinh-the-va-glaucoma/glaucoma-thien-dau-thong">
                    Glaucoma - Thiên đầu thống
                  </a>
                </li>
              </ul>
            </li>
            <li className="col-sm-4">
              <a href="https://tueanh.vn/dich-vu/benh-ly-ket-mac-giac-mac">
                BỆNH LÝ MI MẮT VÀ KẾT, GIÁC MẠC
              </a>
              <ul className="clearfix">
                <li>
                  <a href="https://tueanh.vn/benh-ly-ket-mac-giac-mac/viem-loet-giac-mac">
                    Viêm loét giác mạc
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-ket-mac-giac-mac/loan-duong-giac-mac">
                    Loạn dưỡng giác mạc
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-ket-mac-giac-mac/viem-ket-mac">
                    Viêm kết mạc (Đau mắt đỏ)
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-ket-mac-giac-mac/mong-thit">
                    Mộng thịt
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-ket-mac-giac-mac/benh-mat-hot">
                    Bệnh mắt hột
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/benh-ly-ket-mac-giac-mac/viem-bo-mi1">
                    Viêm bờ mi
                  </a>
                </li>
              </ul>
            </li>
            <li className="col-sm-4">
              <a href="https://tueanh.vn/dich-vu/kham-benh-cac-goi-kham-mat-toan-dien">
                CÁC GÓI KHÁM MẮT TOÀN DIỆN
              </a>
              <ul className="clearfix">
                <li>
                  <a href="https://tueanh.vn/kham-benh-cac-goi-kham-mat-toan-dien/kham-mat-toan-dien-nguoi-lon">
                    Khám mắt toàn diện người lớn
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/kham-benh-cac-goi-kham-mat-toan-dien/kham-mat-toan-dien-tre-em">
                    Khám mắt toàn diện trẻ em
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/kham-benh-cac-goi-kham-mat-toan-dien/do-khuc-xa-khach-quan">
                    Đo khúc xạ khách quan
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/kham-benh-cac-goi-kham-mat-toan-dien/chup-ban-do-giac-mac">
                    Chụp bản đồ giác mạc
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/kham-benh-cac-goi-kham-mat-toan-dien/sieu-am-mat">
                    Siêu âm mắt
                  </a>
                </li>
                <li>
                  <a href="https://tueanh.vn/kham-benh-cac-goi-kham-mat-toan-dien/chup-cat-lop-vong-mac">
                    Chụp cắt lớp võng mạc
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className="sub-menu-style1 sub-menu">
          <a href="https://tueanh.vn/phuong-phap-orthok">Công nghệ Ortho K</a>
        </li>
        <li className="sub-menu-style1 sub-menu">
          <a href="https://tueanh.vn/benh-mat-man-tinh">Bệnh mắt mạn tính</a>
        </li>
        <li className="sub-menu-style1 sub-menu">
          <a href="https://tueanh.vn/tin-tuc-su-kien"> Tin tức </a>
        </li>
        <li className="sub-menu-style1 sub-menu">
          <a href="https://tueanh.vn/lien-he"> Liên hệ </a>
        </li>

        <li className="sub-menu-style1 sub-menu">
          <a href="/">Thử kính</a>
        </li>
      </ul>
    </>
  );
};
