import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useApiData } from "../../store/ApiDataProvider";
import { slugify } from "../../lib/StrUtils";
import { formatNumber } from "../../lib/NumberUtils";
import "./Category.scss";
import { Rating } from "react-simple-star-rating";
import { Box, Slider } from "@mui/material";
import { formatVND } from "../../lib/Utils";

var debounce: any;

//@ts-ignore
export default function Category() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { apiData } = useApiData();
  const [glassezs, setGlassezs] = useState([]);
  const [filters, setFilters] = useState([]);
  const [category, setCategory] = useState(Object);
  const [price, setPrice] = React.useState<number[]>([0, 10000]);
  const [catFilters, setCatFilters] = useState<number[]>([]);

  useEffect(() => {
    setGlassezs(
      (apiData.glassezs as []).filter((item: any) => item.category_id == id)
    );
    (apiData.categorys as []).filter((item: any) => {
      if (item.id == id) {
        setCategory(item);
      }
    });
    console.log(apiData.filters);
    setFilters(apiData.filters);
  }, [apiData, id]);

  const handlePriceFilter = (event: Event, newValue: number | number[]) => {
    const value = newValue as number[];
    setPrice(value);
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      updateGlassezs(value, catFilters);
    }, 500);
  };

  const handleFilter = (e: any) => {
    const value = Number(e.target.value);
    const existIndex = catFilters.findIndex((c) => c === value);
    if (existIndex >= 0) {
      catFilters.splice(existIndex, 1);
    } else {
      catFilters.push(value);
    }
    setCatFilters(catFilters);
    updateGlassezs(price, catFilters);
  };

  function updateGlassezs(priceRange: number[], categories: number[]) {
    setGlassezs(
      (apiData.glassezs as []).filter(
        (item: any) =>
          item.price >= priceRange[0] * 1000 &&
          item.price <= priceRange[1] * 1000 &&
          (!categories.length ||
            item.filter_ids?.find((id: number) => categories.includes(id)))
      )
    );
  }

  return (
    <div className="category-page">
      <div className="container">
        <div
          className="head"
          style={{ backgroundImage: `url(${category.thumb})` }}
        >
          <div>
            <div className="title">{category.name}</div>
            <div className="breadcrump">
              <Link to="/">Home</Link>
              <Box component="span" mx={1}>
                &gt;
              </Box>
              {category.name}
            </div>
          </div>
        </div>

        <div className="main">
          <div className="filters">
            <ul className="cat">
              <li className="cat-filter">Giá</li>
              <Slider
                defaultValue={100}
                step={100}
                value={price}
                valueLabelDisplay="auto"
                min={100}
                max={10000}
                onChange={handlePriceFilter}
                style={{ margin: 0 }}
                color="secondary"
              />
              <p style={{ margin: 0 }}>
                Từ {formatVND(price[0])} đến {formatVND(price[1])}
              </p>
            </ul>
            {filters.map((filterCat: any, index) => (
              <ul className="cat" key={"filter-cat-item-" + index}>
                <li className="cat-filter">{filterCat.name}</li>
                <ul className="cat-items">
                  {filterCat.items.map((filItem: any, idx: any) => (
                    <li key={"filter-item-" + idx}>
                      <label>
                        <input
                          value={filItem.id}
                          type="checkbox"
                          onClick={handleFilter}
                        />
                        {filItem.name}
                      </label>
                    </li>
                  ))}
                </ul>
              </ul>
            ))}
          </div>
          <div className="items">
            {glassezs.map((item: any, index) => {
              return (
                <div
                  key={"gallery-items" + index}
                  className="item col-sm-4"
                  onClick={() => {
                    navigate("/glasses/" + slugify(item.title) + "/" + item.id);
                  }}
                >
                  <div className="img">
                    <img src={item.src} />
                  </div>
                  <div className="foot-area">
                    <div className="title">
                      {item.title} {item.code}
                    </div>
                    <div className="price">{formatNumber(item.price)}đ</div>
                    <div className="rating">
                      <Rating readonly initialValue={item.star} size={20} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
