import React, { useEffect } from "react";
import { FaceLandmarker, FaceLandmarkerResult } from "@mediapipe/tasks-vision";

export default function GlassesCanvas({
  //@ts-ignore
  videoWidth,
  //@ts-ignore
  video,
  //@ts-ignore
  results,
  //@ts-ignore
  glassImage,

  //@ts-ignore
  setLoading,
}) {
  let canvasCtx: CanvasRenderingContext2D;
  let canvasElement: HTMLCanvasElement;
  useEffect(() => {
    canvasElement = document.getElementById(
      "output_canvas"
    ) as HTMLCanvasElement;
    canvasCtx = canvasElement.getContext("2d")!;
    // const ratio = video.clientHeight / video.clientWidth;
    canvasElement.style.width = video.clientWidth + "px";
    canvasElement.style.height = video.clientHeight + "px";
    canvasElement.width = video.clientWidth;
    canvasElement.height = video.clientHeight;
    drawResult();
  });

  const drawResult = async () => {
    if ((results as FaceLandmarkerResult).faceLandmarks) {
      for (const landmarks of results.faceLandmarks) {
        const getPosMin = (listIndex: any) => {
          var listPoints = [];
          for (var i = 0; i < listIndex.length; i++) {
            const indexObj = listIndex[i];
            for (var j = indexObj.start; j <= indexObj.end; j++) {
              listPoints.push(landmarks[j]);
            }
          }
          var minX = 0;
          var minY = 0;
          var maxX = 0;
          var maxY = 0;
          for (var i = 0; i < listPoints.length; i++) {
            if (minX == 0 || listPoints[i].x < minX) {
              minX = listPoints[i].x;
            }
            if (minY == 0 || listPoints[i].y < minY) {
              minY = listPoints[i].y;
            }
            if (maxX == 0 || listPoints[i].x > maxX) {
              maxX = listPoints[i].x;
            }
            if (maxY == 0 || listPoints[i].y > maxY) {
              maxY = listPoints[i].y;
            }
          }
          return {
            minX: minX * canvasElement.width,
            minY: minY * canvasElement.height,
            maxX: maxX * canvasElement.width,
            maxY: maxY * canvasElement.height,
          };
        };
        const ovalPos = getPosMin(FaceLandmarker.FACE_LANDMARKS_FACE_OVAL);

        drawGlass(ovalPos.minX, ovalPos.minY, ovalPos.maxX - ovalPos.minX);
      }
    }
  };

  function drawGlass(x: number, y: number, w: number) {
    setLoading(false);
    const h = (glassImage.naturalHeight * w) / glassImage.naturalWidth;
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(glassImage, x, y + h - 5, w, h);
  }

  return <canvas id="output_canvas"></canvas>;
}
