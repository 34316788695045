import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import axios from "axios";

export const ApiDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [apiData, setApiData] = useState<ApiData>({
    glassezs: [],
    categorys: [],
    filters: [],
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get(apiUrl).then((data) => {
      setApiData({
        glassezs: data.data.glasses.map((e: any) => {
          return {
            id: e.id,
            category_id: e.category_id,
            src: e.frame_image,
            title: e.name,
            description: e.description,
            imgs: e.imgs,
            price: e.price,
            detail: e.detail,
            code: e.code,
            star: e.star,
            filter_ids: e.filter_ids,
          };
        }),
        categorys: data.data.categorys.map((e: any) => {
          return {
            id: e.id,
            thumb: e.thumb,
            name: e.name,
          };
        }),
        filters: data.data.filters.map((e: any) => {
          return {
            id: e.id,

            name: e.name,
            items: e.items,
          };
        }),
      });
    });
  }, []);

  return (
    <ApiDataContext.Provider value={{ apiData, setApiData }}>
      {children}
    </ApiDataContext.Provider>
  );
};

export const useApiData = () => {
  const context = useContext(ApiDataContext);
  if (!context) {
    throw new Error("useApiData must be used within an ApiDataProvider");
  }
  return context;
};

// Định nghĩa kiểu cho dữ liệu từ API
interface ApiData {
  glassezs: [];
  categorys: [];
  filters: [];
}

interface ApiDataContextType {
  apiData: ApiData;
  setApiData: React.Dispatch<React.SetStateAction<ApiData>>;
}

const ApiDataContext = createContext<ApiDataContextType | null>(null);
